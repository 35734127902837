<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo" v-if="insert"/>
						</div>
					</template>
					<Column field="especialidad" header="Especialidad" :sortable="true">
						<template #body="slotProps">
                            {{slotProps.data.especialidad}}
						</template>
					</Column>
					<Column field="especialista" header="Especialista" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.especialista}}
						</template>
					</Column>
					<Column field="espacio" header="Ubicación" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.espacio}}
						</template>
					</Column>
					<Column header="Horas" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.total}}
						</template>
					</Column>
					<Column header="N° Pacientes"  headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.pacientes}}
						</template>
					</Column>
					<Column header="Estatus" headerStyle="width: 120px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success"  v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger"  v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Acciones" headerStyle="width: 150px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="Editar(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="Confirmar(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="productDialog" :style="{width: size}" header="Editar" :modal="true" class="p-fluid" :maximizable="true">
			<div class="fluid">       
				<div class="grid fluid mt-3">
                    <div class="col-6">
						<label for="name">Especialidad</label>
						<Dropdown v-model="product.especialidad" :options="this.$store.state.especialidades" optionLabel="nombre" placeholder="Selecciona..." @change="Filtrar"></Dropdown>
						<small class="p-invalid" v-if="!product.especialidad">Es requirido</small>
					</div>
					<div class="col-6">
						<label for="name">Especialistas</label>
						<Dropdown v-model="product.especialista" :options="especialistas" optionLabel="nombre" placeholder="Selecciona..." @change="Disponibilidad"></Dropdown>
						<small class="p-invalid" v-if="!product.especialista">Es requirido</small>
					</div>
					<div class="col-6">
						<label for="name">Ubicación</label>
						<Dropdown v-model="product.espacio" :options="espacios" optionLabel="nombre" placeholder="Selecciona..."  @change="Disponibilidad"></Dropdown>
						<small class="p-invalid" v-if="!product.espacio">Es requirido</small>
					</div>
					<div class="col-2">
						<label for="name">N° Pacientes</label>
						<InputNumber id="cedula" v-model="product.pacientes" integeronly required="true" min="1"/>
						<small class="p-invalid" v-if="!product.pacientes">Es requerido.</small>
					</div>
					<div class="col-12" v-if="product.especialidad && product.especialista && product.espacio">
						{{product.horario[0]}}
						<DataTable :value="product.horario" :paginator="false" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
								:rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<Column field="hora" header="Hora">
								<template #body="slotProps">
									{{slotProps.data.nombre}}
								</template>
							</Column>
							<Column field="lun" header="LUN" headerStyle="width: 20px">
								<template #body="slotProps">
									<Checkbox id="binary" v-model="slotProps.data.lun" :binary="true" v-if="slotProps.data.lun!=null"/>
								</template>
							</Column>
							<Column field="mar" header="MAR" headerStyle="width: 20px">
								<template #body="slotProps">
									<Checkbox id="binary" v-model="slotProps.data.mar" :binary="true" v-if="slotProps.data.mar!=null"/>
								</template>
							</Column>
							<Column field="mie" header="MIE" headerStyle="width: 20px">
								<template #body="slotProps">
									<Checkbox id="binary" v-model="slotProps.data.mie" :binary="true" v-if="slotProps.data.mie!=null"/>
								</template>
							</Column>
							<Column field="jue" header="JUE" headerStyle="width: 20px">
								<template #body="slotProps">
									<Checkbox id="binary" v-model="slotProps.data.jue" :binary="true" v-if="slotProps.data.jue!=null"/>
								</template>
							</Column>
							<Column field="vie" header="VIE" headerStyle="width: 20px">
								<template #body="slotProps">
									<Checkbox id="binary" v-model="slotProps.data.vie" :binary="true" v-if="slotProps.data.vie!=null"/>
								</template>
							</Column>
							<Column field="sab" header="SAB" headerStyle="width: 20px">
								<template #body="slotProps">
									<Checkbox id="binary" v-model="slotProps.data.sab" :binary="true" v-if="slotProps.data.sab!=null"/>
								</template>
							</Column>
							<Column field="dom" header="DOM" headerStyle="width: 20px">
								<template #body="slotProps">
									<Checkbox id="binary" v-model="slotProps.data.dom" :binary="true" v-if="slotProps.data.dom!=null"/>
								</template>
							</Column>
						</DataTable>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="deleteDialog"  style="width: 30vw" header="Eliminar" :modal="true">
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres eliminar?</span>
			</div>
			<template #footer>
				<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Salud','Horarios');

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				horas: [],
				productDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '50vw',
                espacios: [],
                especialistas: [],
                data_especialistas: [],

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
                this.especialidades = [{nombre: 'TODOS', code: 0}].concat(this.$store.state.especialidades);
				Consulta.Ini().then(response => {
					//this.$store.state.error = response.result.espacios;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result.horarios;
					this.espacios = response.result.espacios;
					this.horas = response.result.horas;
					this.data_especialistas = response.result.especialistas;
					this.$store.commit('Loading');
				}); 
			},
            Filtrar(){
                this.especialistas = [];
                if(this.product.especialidad){
                    this.especialistas = this.data_especialistas.filter(val => val.especialidad_id == this.product.especialidad.code);
					this.product.especialista = null;
					this.product.horario = this.horas;
                }
				
            },
            Disponibilidad(){
				if(this.product.especialidad && this.product.especialista && this.product.espacio){
					this.product.horario = this.horas;
					Consulta.Procesar('Horario',{
						product: this.product,
					}).then(response => {
						//this.$store.state.error = response.result.disp[0];
						this.$store.commit('Loading');
						if (response.result) {
							response.result.disponible.forEach(element => {
								for(var i = 0; i < this.product.horario.length; ++i) { 
									if(element.hora_id==this.product.horario[i]['id']){
										if(element.dia_id==1){
											this.product.horario[i]['lun'] = true;
											this.product.horario[i]['lun']['disp'] = this.espacios;
										}
										if(element.dia_id==2){
											this.product.horario[i]['mar'] = true;
											this.product.horario[i]['mar']['disp'] = this.espacios;
										}
										if(element.dia_id==3){
											this.product.horario[i]['mie'] = true;
											this.product.horario[i]['mie']['disp'] = this.espacios;
										}
										if(element.dia_id==4){
											this.product.horario[i]['jue'] = true;
											this.product.horario[i]['jue']['disp'] = this.espacios;
										}
										if(element.dia_id==5){
											this.product.horario[i]['vie'] = true;
											this.product.horario[i]['vie']['disp'] = this.espacios;
										}
										if(element.dia_id==6){
											this.product.horario[i]['sab'] = true;
											this.product.horario[i]['sab']['disp'] = this.espacios;
										}
										if(element.dia_id==0){
											this.product.horario[i]['dom'] = true;
											this.product.horario[i]['dom']['disp'] = this.espacios;
										}
									}
								}
							}); 
							response.result.ocupado.forEach(element => {
								for(var j = 0; j < this.product.horario.length; ++j) { 
									if(element.hora_id==this.product.horario[j]['id']){
										if(element.dia_id==1){this.product.horario[j]['lun'] = null;}
										if(element.dia_id==2){this.product.horario[j]['mar'] = null;}
										if(element.dia_id==3){this.product.horario[j]['mie'] = null;}
										if(element.dia_id==4){this.product.horario[j]['jue'] = null;}
										if(element.dia_id==5){this.product.horario[j]['vie'] = null;}
										if(element.dia_id==6){this.product.horario[j]['sab'] = null;}
										if(element.dia_id==0){this.product.horario[j]['dom'] = null;}
									}
								}
							}); 

						} 
					});
				}
            },
			Nuevo() {
				this.product.horario = this.horas;
                this.especialistas = [];
				this.nuevo = true;
				this.product = {};
                this.product.id = '';
                this.product.pacientes = 1;
				this.productDialog = true;
			},
			Editar(product) {
				this.nuevo = false;
				this.product = {...product};
				this.product.horario = this.horas;
                this.product.especialidad =  this.$store.state.especialidades.filter(val => val.code == this.product.especialidad_id)[0];
                this.Filtrar();
                this.product.especialista =  this.especialistas.filter(val => val.id == this.product.especialista_id)[0];
                this.product.espacio = this.espacios.filter(val => val.id == this.product.espacio_id)[0];
                //this.product.dia =  this.$store.state.dias.filter(val => val.id == this.product.dia_id)[0];
                //this.product.hora =  this.$store.state.horas.filter(val => val.id == this.product.hora_id)[0];
                //this.Filtrar2();
                this.Disponibilidad();
				
				
				
				
				this.productDialog = true;
			},
			Guardar() {
				if (this.product.especialidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Especialidad', life: 5000});
                } else if (this.product.especialista == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Especialista', life: 5000});
				/*
				if (this.product.dia == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Día', life: 5000});
                } else if (this.product.hora == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Hora', life: 5000});
                } else if (this.product.hora == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Hora', life: 5000});
                } else if (this.product.especialidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Especialidad', life: 5000});
                } else if (this.product.especialista == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Especialista', life: 5000});
				*/
				} else if (this.product.espacio == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Ubicación', life: 5000});
				} else {
					var caso = 'UPD';
					if (this.nuevo) {caso = 'INS';}
					Consulta.Procesar(caso,{
						product: this.product,
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.Mostrar();
							this.productDialog = false;
							this.product = {};
						}
					});
				}
			},
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
					this.$store.commit('Loading');
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
